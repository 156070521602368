<template>
	<div>
		<div 
			v-if="showNavigation && isMobile"
			class="dim"
			@click="showNavigation = false">
			
		</div>
		
		<transition 
			v-if="showNavigation"
			name="slide-in"
			appear>
			
			<nav 
				class="
					flex flex-col bg-gray-darkest w-64
					fixed top-0 left-0 right-0 bottom-0
					md:static
				"
				style="z-index: 100;">
				
				<div class="flex-grow">
					<div 
						class="fixed top-0 left-0 flex m-4 w-12 h-12 items-center justify-center md:hidden"
						@click="showNavigation = false">
						
						<i class="fas fa-times text-white text-xl" />
					</div>
					
					<router-link 
						:to="{ name: 'home', params: {accountId} }"
						class="h-48 flex items-center justify-center">
						
						<img src="/assets/logo.svg" />
					</router-link>
					
					<router-link 
						:to="{ name: 'home', params: {accountId} }"
						class="item">
						
						<SvgIcon icon="overview" />
						
						Oversikt
					</router-link>
					
					<router-link 
						:to="{ name: 'departments', params: {accountId} }"
						class="item">
						
						<SvgIcon icon="departments" />
						
						Avdelinger & Produkter
					</router-link>
					
					<router-link 
						:to="{ name: 'campaigns', params: {accountId} }"
						class="item">
						
						<SvgIcon icon="campaigns" />
						
						Kampanjer
					</router-link>
					
					<router-link 
						:to="{ name: 'access', params: {accountId} }"
						class="item">
						
						<SvgIcon icon="access" />
						
						Brukere &amp; Tilgang
					</router-link>
					
					<router-link 
						:to="{ name: 'dashboards', params: {accountId} }"
						class="item ">
						
						<SvgIcon icon="dashboards" />
						
						Dashboards
					</router-link>
					
					<router-link 
						:to="{ name: 'messages', params: {accountId} }"
						class="item flex-none">
						
						<SvgIcon icon="messages" />
						
						Meldingssenter
					</router-link>
					
					<router-link 
						:to="{ name: 'settings', params: {accountId} }"
						class="item flex-none">
						
						<SvgIcon icon="settings" />
						
						Innstillinger
					</router-link>
					
					<hr class="border-b border-dashed my-4" v-if="signedInUser || isSuperAdmin">
					
					<router-link 
						:to="{ name: 'app-departments'}"
						class="item">
						
						<SvgIcon icon="userMode" />
						
						Brukermodus
					</router-link>
					
					<router-link 
						v-if="signedInUser && isSuperAdmin"
						:to="{ name: 'superadmin-home'}"
						class="item">
						
						<SvgIcon icon="superadmin" />
						
						SuperAdmin
					</router-link>
				</div>
				
				<div 
					v-if="signedInUser"
					class="flex-none mb-4">
					
					<a 
						class="item flex-none"
						:class="showKudosGive ? 'text-white bg-black' : ''"
						@click="$emit('setShowKudosGive', !showKudosGive)">
						
						<SvgIcon icon="kudos" />
						
						<span v-if="showKudosGive">Skjul live-aktivitet</span>
						<span v-else>Vis live-aktivitet</span>
					</a>
					
					<div class="py-2 px-8">
						<router-link 
							:to="{ name: 'accounts', params: {accountId} }"
							v-if="signedInUser.signInUserSession.idToken.payload['cognito:groups'] && signedInUser.signInUserSession.idToken.payload['cognito:groups'].length > 1"
							class="">
							
							<div 
								v-if="account && account.name"
								class="truncate">
								
								{{account.name}}
							</div>
							
							{{ signedInUser.attributes.email }}
						</router-link>
						<span v-else>
							{{ signedInUser.attributes.email }}
						</span>
					</div>
					
					<div 
						@click="signOut()"
						class="item cursor-pointer">
						
						<SvgIcon icon="signOut" />
						
						Logg ut
					</div>
				</div>
				<!--<div 
					v-if="isSuperAdmin"
					class="bg-blue-500 p-2 text-center text-white">
					
					Currently in SuperAdmin
				</div> -->
			</nav>
		</transition>
		
		<div
			v-else
			class="fixed top-0 left-0 flex m-4 w-12 h-12 items-center justify-center"
			style="z-index: 100;"
			@click="showNavigation = true">
			
			<i class="fas fa-bars text-black text-xl" />
		</div>
	</div>
</template>

<script>
	import AuthMixins from '@/mixins/AuthMixins';
	
	export default {
		mixins: [
			AuthMixins,
		],
		
		props: { 
			showKudosGive: {
				type: Boolean,
				required: true,
			},
		},
		
		computed: {
			isSuperAdmin(){
				
				if ( 
					this.signedInUser && 
					this.signedInUser.attributes['custom:superadmin'] == 'true') {
					
					return true;
				}
				
				return false;
			},
			
			// showKudosGive(){
			// 	return this.$store.getters.getShowKudosGive;
			// },
		},
		
		data(){
			return {
				showNavigation: true,
				isKudosActive: true,
				
				// showKudosGive: false,
			}
		},
		
		watch:{
			$route(to, from){
				if ( this.isMobile ) {
					this.showNavigation = false;
				}
				return
			}
		},
		
		/* methods: {
			onShowKudosGiveToggle(){
				this.$emit('setShowKudosGive', !this.showKudosGive);
				// this.$store.commit('setShowKudosGive', !this.showKudosGive);
			},
		}, */
	}
</script>


<style lang="scss" scoped>
	.dim {
		@apply opacity-25;
		@apply bg-black;
		// @apply bg-transparent;
		@apply fixed;
		@apply inset-0;
		z-index: 99;
	}
	
	nav {
		.item {
			@apply flex;
			@apply items-center;
			@apply mx-4;
			@apply mb-2;
			@apply p-2;
			@apply rounded-lg;
			@apply text-gray-lighter;
			
			svg {
				@apply w-5;
				@apply h-5;
				@apply mr-4;
				@apply ml-2;
			}
			
			&:hover:not(.router-link-active) {
				// @apply bg-gray-200;
			}
			
			&.router-link-active {
				@apply text-white;
				@apply bg-black;
			}
			
		}
	}
</style>
